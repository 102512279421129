import React from 'react';

import Icon from './Icon';

export default function VideoIcon({
  className = '',
  text = 'Select',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4697 7.53033C12.7626 7.82322 13.2374 7.82322 13.5303 7.53033C13.8232 7.23744 13.8232 6.76256 13.5303 6.46967L10.5303 3.46967C10.2374 3.17678 9.76256 3.17678 9.46967 3.46967L6.46967 6.46967C6.17678 6.76256 6.17678 7.23744 6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033L10 5.06066L12.4697 7.53033Z"
          fill="currentColor"
        />
        <path
          d="M7.53033 12.4697C7.23744 12.1768 6.76256 12.1768 6.46967 12.4697C6.17678 12.7626 6.17678 13.2374 6.46967 13.5303L9.46967 16.5303C9.76256 16.8232 10.2374 16.8232 10.5303 16.5303L13.5303 13.5303C13.8232 13.2374 13.8232 12.7626 13.5303 12.4697C13.2374 12.1768 12.7626 12.1768 12.4697 12.4697L10 14.9393L7.53033 12.4697Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
